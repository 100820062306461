import { useForm } from 'react-hook-form';

import type { NumberingSeries } from '~/types/user';

import { generateEntryNumber, generateEntryNumberPrefix, generateEntryNumberSuffix } from '~/utils/string';

import type { EditEntryNumberFormType } from './types';

export const useEntryNumberForm = (numberingSeries: NumberingSeries) => {
  const form = useForm<EditEntryNumberFormType>({
    defaultValues: {
      nextNumber: numberingSeries.nextNumber,
      prefix: generateEntryNumberPrefix(numberingSeries.format),
      suffix: generateEntryNumberSuffix(numberingSeries.format),
      showSuffix:
        !!numberingSeries.format.suffixSeparator || !!numberingSeries.format.suffixYearMonth || !!numberingSeries.format.suffixText,
    },
  });

  const entryNumber = generateEntryNumber(numberingSeries, {
    nextNumber: form.watch('nextNumber'),
    prefix: form.watch('prefix'),
    suffix: form.watch('suffix'),
  });

  return { form, entryNumber };
};
