import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormField, FormLabel } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';

import { useLineContext } from '../../SectionLines/context';
import { preventNumberInputOnWheel } from '../utils';
import styles from './FormPrice.module.scss';

export const FormPrice = () => {
  const { control } = useInvoiceQuotationFormContext();
  const { isMobile, lineFieldName } = useLineContext();

  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['lines', 'validation']);

  return (
    <FormControl control={control} name={`${lineFieldName}.price`} rules={{ required: true, min: 0.01, max: 100_000 }}>
      {isMobile && <FormLabel>{t('lines:price.header')}</FormLabel>}

      <div className={styles.Wrapper}>
        <span className={styles.EuroPrefix}>€</span>
        <FormField>
          {({ field, fieldState, id }) => (
            <input
              className={classNames(styles.PriceInput, fieldState.invalid && styles.Invalid)}
              id={id}
              inputMode="decimal"
              max={100_000}
              min={0}
              onBlur={field.onBlur}
              onChange={field.onChange}
              onWheel={preventNumberInputOnWheel}
              ref={field.ref}
              step={1}
              type="number"
              value={field.value}
            />
          )}
        </FormField>
      </div>

      {isMobile && (
        <FormErrorMessage
          max={t('validation:max', { attribute: t('lines:quantity.header'), max: formatCurrency(100_000) })}
          min={t('validation:min', { attribute: t('lines:quantity.header'), min: formatCurrency(0.01) })}
          required={t('validation:required')}
        />
      )}
    </FormControl>
  );
};
