export const validation = {
  client: {
    emails: {
      eachTypeSelected: "Spécifiez au moins une adresse e-mail pour chaque type d'e-mail",
      invalid: 'Ne saisissez que des adresses e-mail valides.',
      noEmptySelection: "Sélectionnez au moins un type d'e-mail par adresse e-mail",
      required: 'Saisissez au moins une adresse e-mail.',
    },
  },
  dateTomorrow: "La date d'expiration doit être au moins demain.",
  email: {
    invalid: 'Saisir une adresse e-mail valide.',
  },
  iban: 'Saisirun numéro IBAN valide.',
  integrations: {
    accountantEmailInvalid: "Votre comptable n'a pas encore de compte sur notre plateforme Credit.",
  },
  invitations: {
    noDoubleInvite: 'Vous avez déjà envoyé une invitation à cette adresse e-mail.',
    noSelfInvite: 'Vous ne pouvez pas vous inviter vous-même !',
  },
  invoice: {
    lines: {
      minimumOne: 'Ajoutez au moins une ligne de facturation à la facture.',
      minimumOneNotFree: "Ajoutez au moins une ligne de facture qui n'est pas gratuite.",
    },
  },
  max: '{{attribute}} ne peut pas être supérieur à {{max}}.',
  maxDiscount: 'La réduction ne peut pas dépasser le prix total.',
  maxFileSize: 'Le fichier ne peut être plus gros que {{max}} mégaoctet.',
  maxLength: '{{attribute}} ne peut pas dépasser {{max}} caractères.',
  maxLengthInLanguage: '{{attribute}} ne doit pas dépasser {{max}} caractères ({{lang}}).',
  min: '{{attribute}} doit être au moins {{min}}.',
  minLength: '{{attribute}} doit être supérieur à {{min}} caractères.',
  password: {
    invalid: 'Ce mot de passe doit être identique au nouveau mot de passe.',
  },
  quotation: {
    entrynumber: {
      invalid: 'Ce numéro de devis a déjà été utilisé pour un précédent devis.',
    },
    lines: {
      minimumOne: "Ajouter au moins une ligne à l'offre.",
      minimumOneNotFree: "Ajoutez au moins une ligne qui n'est pas gratuite.",
    },
  },
  required: 'Ce champ est obligatoire.',
  requiredAttribute: '{{attribute}} est obligatoire.',
  requiredInLanguage: 'Ce champ est obligatoire ({{lang}}).',
  signContract: {
    message: 'Entrez « Lu et approuvé »',
  },
  vatNumber: {
    belgianFormat: "Saisissez un numéro d'entreprise valide. Il se compose de 10 chiffres et commence par 0 ou 1.",
    inactive: "Cette organisation n'est pas active.",
    invalid: "Le numéro de TVA n'est pas valide, pouvez-vous le demander à nouveau ?",
    unique:
      'Vous avez déjà créé un client avec ce numéro de TVA. <0>Cliquez ici </0> pour ajouter une nouvelle personne de contact à ce client. </0>',
  },
};
