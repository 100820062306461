import { saveAs } from 'file-saver';

import { env } from '~/constants/env';

import {
  BusinessConflictError,
  ClientError,
  FetchError,
  MaintenanceError,
  NotFoundError,
  ServerError,
  TooManyRequestsError,
  UnauthenticatedError,
} from './errors';

type FetchOptions = {
  body?: unknown;
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  responseType?: 'json' | 'blob';
  token?: string;
};

export const request = async (path: `/${string}`, options: FetchOptions = {}): Promise<unknown> => {
  const method = options.method ?? 'GET';
  const headers = {
    Accept: 'application/json',
    ...(options.token && { Authorization: `Bearer ${options.token}` }),
    ...(!(options.body instanceof FormData) && { 'Content-Type': 'application/json' }),
  };
  const body = options.body instanceof FormData ? options.body : JSON.stringify(options.body ?? null);

  const controller = new AbortController();
  const abortId = setTimeout(() => controller.abort(), 30_000);

  try {
    const response = await fetch(`${env.API_URL}${path}`, {
      method,
      headers,
      ...(method !== 'GET' && { body }),
      signal: controller.signal,
    });

    clearTimeout(abortId);

    if (!response.ok) await throwErrorResponse(response);

    if (response.status === 204) return null;

    return options.responseType === 'blob' ? response.blob() : response.json();
  } catch (error) {
    throwFetchError(error);
  }
};

export const download = async (path: `/${string}`, options: Omit<FetchOptions, 'responseType'>): Promise<unknown> => {
  const method = options.method ?? 'GET';
  const headers = {
    Authorization: `Bearer ${options.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const body = JSON.stringify(options.body ?? null);

  try {
    const response = await fetch(`${env.API_URL}${path}`, {
      method,
      headers,
      ...(method !== 'GET' && { body }),
    });

    if (!response.ok) await throwErrorResponse(response);

    if (response.status === 204) return;

    const blob = await response.blob();

    if (blob.size === 0) return;

    const contentDispositionHeader = response.headers.get('Content-Disposition') ?? '';
    const fileNameRegex = /filename\*?="?(?<name>[^;"]+)"?;?/i;
    const fileName = contentDispositionHeader.match(fileNameRegex)?.groups?.name.trim() || 'download';

    saveAs(blob, fileName);
  } catch (error) {
    throwFetchError(error);
  }
};

// Helper to transform errors to the corresponding HttpError class.
async function throwErrorResponse(response: Response) {
  if (response.ok) return;

  if (response.status === 503) throw new MaintenanceError();
  if (response.status >= 500) throw new ServerError(response.status);
  if (response.status === 429) throw new TooManyRequestsError();
  if (response.status === 404) throw new NotFoundError();
  if (response.status === 401) throw new UnauthenticatedError();

  const error = await response.json();

  if (response.status === 409) throw new BusinessConflictError(error.errorCode);

  throw new ClientError(response.status, { cause: error });
}

// Helper to transform errors to the corresponding FetchError class,
// or rethrow if not a FetchError.
function throwFetchError(error: unknown) {
  if (!(error instanceof Error)) throw error;

  // Timed out
  if (error.name === 'AbortError') {
    throw new FetchError('Request timed out', { cause: error });
  }

  // Network error, CORS error, ...
  if (error.message === 'Failed to fetch' || error.message === 'Load failed') {
    throw new FetchError('Could not reach the server', { cause: error });
  }

  throw error;
}
