import * as stylex from '@stylexjs/stylex';

import { grayColors, radius, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  button: {
    padding: 0,
    borderStyle: 'none',
    borderRadius: radius.none,
    color: systemColors.textMuted,
    backgroundColor: grayColors.white,
  },
});
