import * as stylex from '@stylexjs/stylex';

import { animationSpeed, fontSize, fontWeight, grayColors, radius, spacing } from '~/styles/tokens.stylex';

const isFocused = ':is([data-focus])';

export const styles = stylex.create({
  button: {
    position: 'fixed',
    top: 4,
    left: 4,
    padding: spacing.tiny,
    borderRadius: radius.xxxsmall,
    color: grayColors.white,
    backgroundColor: grayColors.gray600,
    outlineStyle: {
      default: 'none',
      [isFocused]: 'solid',
    },
    outlineWidth: '1px',
    outlineOffset: '1px',
    outlineColor: grayColors.gray600,
    transitionProperty: animationSpeed.veryFast,
    zIndex: 2147483647,
  },

  items: {
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxsmall,
    padding: spacing.xxxsmall,
    borderRadius: radius.xxxsmall,
    color: grayColors.white,
    fontSize: fontSize.small,
    backgroundColor: grayColors.gray600,
    outlineStyle: 'none',
    zIndex: 2147483647,
  },

  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing.xxxsmall,
  },

  header: {
    position: 'relative',
    marginBlockEnd: spacing.tiny,
    marginInline: spacing.xxsmall,
    textAlign: 'center',
    isolation: 'isolate',

    '::after': {
      content: '',
      position: 'absolute',
      insetInline: 0,
      top: '50%',
      zIndex: -1,
      borderBottomStyle: 'solid',
      borderBottomWidth: 0.5,
      borderColor: grayColors.white,
    },
  },

  headerText: {
    paddingInline: spacing.xxsmall,
    fontSize: fontSize.tiny,
    fontWeight: fontWeight.light,
    textTransform: 'uppercase',
    backgroundColor: grayColors.gray600,
  },

  item: {
    paddingInline: spacing.xxsmall,
    paddingBlock: spacing.xxxsmall,
    borderRadius: radius.xxxsmall,
    outlineStyle: {
      default: 'none',
      [isFocused]: 'solid',
    },
    outlineWidth: '1px',
    outlineColor: grayColors.white,
    textAlign: 'start',
  },

  itemButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    gap: spacing.xsmall,
  },

  shortcut: {
    minWidth: 20,
    height: 20,
    paddingInline: spacing.tiny,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Campton',
    fontSize: fontSize.tiny,
    backgroundColor: grayColors.gray400,
    borderRadius: radius.xxxsmall,
  },

  shortcutHidden: {
    visibility: 'hidden',
  },
});
