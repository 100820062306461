import * as stylex from '@stylexjs/stylex';

import type { MediaQueryLaptopAndBelow, MediaQueryMobileAndBelow, MediaQueryTablet, MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { grayColors, radius, spacing, systemColors, themeColors } from '~/styles/tokens.stylex';

const Tablet: MediaQueryTablet = '@media (768px <= width < 1024px)';
const MobileAndBelow: MediaQueryMobileAndBelow = '@media (width < 768px)';
const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';
const LaptopAndBelow: MediaQueryLaptopAndBelow = '@media (width < 1280px)';

export const styles = stylex.create({
  sidebar: {
    minWidth: 0,
    gridArea: 'sidebar',
    position: {
      default: 'sticky',
      [TabletAndBelow]: 'unset',
    },
    top: spacing.normal,
    height: {
      // Height = full screen height - header height - padding (top & bottom) - grid gap
      default: `calc(100vh - 32px - 3 * ${spacing.normal})`,
      [TabletAndBelow]: 'unset',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacing.large,
    paddingInline: {
      default: spacing.xxlarge,
      [MobileAndBelow]: spacing.large,
    },
    paddingBlock: spacing.normal,
    borderRadius: radius.small,
    color: systemColors.textBlack,
    backgroundColor: grayColors.gray025,
    isolation: 'isolate',
  },

  contentWrapper: {
    width: 500,
    maxWidth: '100%',
    marginInline: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  // When the page is scrolled, the sidebar moves up and leaves empty space at the bottom of the screen.
  // This element fills the space to make it look like the sidebar grows.
  bottom: {
    display: {
      default: null,
      [TabletAndBelow]: 'none',
    },

    // Simulating the same width as the sidebar grid area based on the column template,
    // which is "3fr 4fr", or "1fr 1fr" on laptop and below.
    width: {
      default: `calc((100vw - 3 * ${spacing.normal}) * 3 / 7)`,
      [LaptopAndBelow]: `calc((100vw - 3 * ${spacing.normal}) * 1 / 2)`,
    },

    // Height of the header + top padding of page + border radius of the sidebar
    height: `calc(32px + ${spacing.normal} + ${radius.small})`,

    position: 'fixed',
    bottom: spacing.normal,
    left: spacing.normal,
    borderBottomStartRadius: radius.small,
    borderBottomEndRadius: radius.small,
    backgroundColor: grayColors.gray025,
    zIndex: -1,
  },
});

export const checklistStyles = stylex.create({
  illustration: {
    marginInline: 'auto',
    marginBlockStart: '-10%', // Offset because the sparkles at the top are hidden
    width: '50%',
    minWidth: {
      default: 150,
      [MobileAndBelow]: 100,
    },
    transform: {
      default: 'translate(32px, 20px)',
      [MobileAndBelow]: 'translate(0px, 16px)',
    },
  },

  checklist: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    padding: spacing.normal,
    borderRadius: radius.xxsmall,
    color: grayColors.white,
    backgroundColor: themeColors.primary500,
  },

  checklistItem: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
  },

  insuranceFaqLink: {
    color: {
      default: grayColors.white,
      ':hover': themeColors.primary200,
      ':focus': themeColors.primary200,
    },
  },
});

export const kiiaStyles = stylex.create({
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    marginBlockStart: spacing.normal,
    marginBlockEnd: spacing.large,
  },

  eligibilityTest: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
    padding: spacing.normal,
    borderRadius: radius.xxsmall,
    color: grayColors.white,
    backgroundColor: themeColors.primary500,
  },

  button: {
    color: {
      default: grayColors.white,
      ':hover': grayColors.gray800,
    },
    borderColor: {
      default: grayColors.white,
      ':hover': grayColors.gray800,
    },
    backgroundColor: {
      default: 'transparent',
      ':hover': grayColors.white,
    },
  },

  hiddenButton: {
    display: 'none',
  },

  illustration: {
    width: '60%',
    minWidth: 200,
    marginInlineStart: 'auto',
    marginBlockStart: {
      default: '-25%',
      [Tablet]: '-20%',
      [MobileAndBelow]: '-15%',
    },
  },
});

export const testimonialStyles = stylex.create({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap', // Hide testimonials that don't fit by wrapping them out of view
    justifyContent: 'space-evenly',
    gap: spacing.large,
  },

  testimonialWrapper: {
    display: 'flex',
    alignItems: 'start',
    gap: spacing.normal,
  },

  testimonialWrapperReverse: {
    flexDirection: 'row-reverse',
  },

  testimonial: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    padding: spacing.normal,
    borderRadius: radius.xxsmall,
    backgroundColor: grayColors.white,
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
  },

  stars: {
    display: 'flex',
    color: '#FFD600',
  },

  illustration: {
    marginTop: spacing.xsmall,
    width: 64,
  },
});
