import * as stylex from '@stylexjs/stylex';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { match, Pattern } from 'ts-pattern';

import { useLogin } from '~/api/auth';
import { BusinessConflict, BusinessConflictError, FetchError, HttpError, TooManyRequestsError } from '~/api/errors';
import { Form, FormField, FormInput, FormValidationError, Label, ValidationError } from '~/components/Form';
import { Alert, AlertDescription, AlertTitle, Button, ExternalLink, Flex, LinkButton, Separator } from '~/components/UI';
import { EMAIL } from '~/constants/regex';
import { routes } from '~/providers/RouterProvider/router.routes';

import type { LoginFormType } from './Login.types';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { AuthLink } from '../AuthLink/AuthLink';
import { ResendVerificationEmail } from '../ResendVerificationEmail/ResendVerificationEmail';
import { styles } from './Login.styles';

export const Login = () => {
  const mutation = useLogin();

  const { control, handleSubmit } = useForm<LoginFormType>({
    defaultValues: {
      emailAddress: '',
      password: '',
    },
  });

  // Snapshot of the email address input field at the time of submit,
  // which is used to resend the verification email.
  const [emailAddressSnapshot, setEmailAddressSnapshot] = useState('');

  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation(['auth', 'common', 'validation']);

  const onSubmit = handleSubmit((data) => {
    setEmailAddressSnapshot(data.emailAddress);

    mutation.mutate(data, {
      onSuccess: () => navigate(state?.redirectPath || routes.dashboard, { replace: true }),
    });
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:loginPage.header.pill')}
        subtitle={t('auth:loginPage.header.subtitle')}
        title={t('auth:loginPage.header.title')}
      />

      {state?.isSessionExpired && (
        <Alert variant="warning">
          <AlertTitle>{t('auth:loginPage.sessionExpiredAlert.title')}</AlertTitle>
          <AlertDescription>{t('auth:loginPage.sessionExpiredAlert.description')}</AlertDescription>
        </Alert>
      )}

      <Form {...stylex.props(styles.form)} onSubmit={onSubmit}>
        <Flex direction="column" gap="normal">
          <FormField control={control} name="emailAddress" rules={{ required: true, pattern: EMAIL }}>
            <Label>{t('auth:loginPage.form.emailField.label')}</Label>
            <FormInput type="email" />
            <FormValidationError pattern={t('validation:email.invalid')} required={t('validation:required')} />
          </FormField>

          <FormField control={control} name="password" rules={{ required: true }}>
            <Label>{t('auth:loginPage.form.passwordField.label')}</Label>
            <FormInput type="password" />
            <FormValidationError required={t('validation:required')} />
          </FormField>

          {mutation.isError && (
            <ValidationError>
              {match(mutation.error)
                .with(
                  Pattern.instanceOf(BusinessConflictError),
                  (error) => error.errorCode === BusinessConflict.IneligibleUserCannotLogIn,
                  () => (
                    <Alert variant="info">
                      <AlertTitle>{t('auth:loginPage.errors.ineligible.title')}</AlertTitle>
                      <AlertDescription>{t('auth:loginPage.errors.ineligible.description')}</AlertDescription>
                    </Alert>
                  ),
                )
                .with(
                  Pattern.instanceOf(BusinessConflictError),
                  (error) => error.errorCode === BusinessConflict.UserWithUnverifiedEmailCannotLogIn,
                  () => (
                    <Alert variant="info">
                      <AlertTitle>{t('auth:loginPage.errors.unverifiedEmail.title')}</AlertTitle>
                      <AlertDescription>
                        {t('auth:loginPage.errors.unverifiedEmail.description')}
                        <Separator spacing="xxsmall" />
                        <ResendVerificationEmail emailAddress={emailAddressSnapshot} linkStyles={styles.resendVerificationEmailLink} />
                      </AlertDescription>
                    </Alert>
                  ),
                )
                .with(
                  Pattern.instanceOf(BusinessConflictError),
                  (error) => error.errorCode === BusinessConflict.LoginInvalidEmailOrPassword,
                  () => t('auth:loginPage.errors.invalidEmailOrPassword'),
                )
                .with(Pattern.instanceOf(TooManyRequestsError), () => t('common:errors.tooManyRequests'))
                .with(Pattern.instanceOf(HttpError), () => (
                  <Trans components={{ email: <ExternalLink /> }} i18nKey="common:errors.httpErrorWithLink" />
                ))
                .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
                .otherwise(() => t('common:errors.unknown'))}
            </ValidationError>
          )}

          <Flex alignItems="center" gap="small" justifyContent="spaceBetween" wrap>
            <Button loading={mutation.isPending} type="submit">
              {t('auth:loginPage.form.submitButton')}
            </Button>

            <LinkButton to={routes.forgotPassword} variant="link">
              {t('auth:loginPage.forgotPasswordLink')}
            </LinkButton>
          </Flex>
        </Flex>
      </Form>

      <AuthLink to="registration" />
    </AuthLayoutContent>
  );
};
