export const shouldThrowError = (error: unknown): boolean => error instanceof UnauthenticatedError || error instanceof MaintenanceError;
export const shouldCaptureErrorInSentry = (error: unknown): boolean =>
  !(error instanceof MaintenanceError || error instanceof TooManyRequestsError || error instanceof BusinessConflictError);

export class FetchError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message ?? 'A network error occurred', options);
    this.name = 'RequestError';
  }
}

export class HttpError extends Error {
  public statusCode: number;

  constructor(statusCode: number, options?: ErrorOptions) {
    super(`HTTP error ${statusCode}`, options);
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    Error.captureStackTrace?.(this, this.constructor);
  }
}

export class ServerError extends HttpError {
  constructor(statusCode = 500, options?: ErrorOptions) {
    super(statusCode, options);
  }
}

export class ClientError extends HttpError {
  constructor(statusCode = 400, options?: ErrorOptions) {
    super(statusCode, options);
  }
}

export class MaintenanceError extends ServerError {
  constructor(options?: ErrorOptions) {
    super(503, options);
  }
}

export class UnauthenticatedError extends ClientError {
  constructor(options?: ErrorOptions) {
    super(401, options);
  }
}

export class NotFoundError extends ClientError {
  constructor(options?: ErrorOptions) {
    super(404, options);
  }
}

export class TooManyRequestsError extends ClientError {
  constructor(options?: ErrorOptions) {
    super(429, options);
    this.name = 'TooManyRequestsError';
  }
}

export class BusinessConflictError extends ClientError {
  constructor(
    public errorCode: BusinessConflict,
    options?: ErrorOptions,
  ) {
    super(409, options);
  }
}

export enum BusinessConflict {
  LoginInvalidEmailOrPassword = 'LoginInvalidEmailOrPassword',
  IneligibleUserCannotLogIn = 'IneligibleUserCannotLogIn',
  UserWithUnverifiedEmailCannotLogIn = 'UserWithUnverifiedEmailCannotLogIn',
  IneligibleUserCannotResetPassword = 'IneligibleUserCannotResetPassword',
  UserWithUnverifiedEmailCannotResetPassword = 'UserWithUnverifiedEmailCannotResetPassword',
  PasswordResetTokenDoesNotExist = 'PasswordResetTokenDoesNotExist',
  ApprovedInvoiceCannotBeLockedByUser = 'ApprovedInvoiceCannotBeLockedByUser',
  ExistingUserCannotBeInvited = 'ExistingUserCannotBeInvited',
}
