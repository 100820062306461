import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { routes } from '../RouterProvider/router.routes';
import { CommandMenuItem } from './CommandMenuItem';

export const AppCommandMenuItems = () => {
  const { i18n } = useTranslation();
  const [originalLanguage, setOriginalLanguage] = useState(i18n.language);
  const isCiMode = i18n.language === 'cimode';

  const navigate = useNavigate();
  const isDesignSystemRoute = !!useMatch(routes.designSystem);

  return (
    <>
      <CommandMenuItem
        group="General"
        label={isCiMode ? 'Hide translation keys' : 'Show translation keys'}
        onClick={() => {
          if (!isCiMode) setOriginalLanguage(i18n.language);
          i18n.changeLanguage(isCiMode ? originalLanguage : 'cimode');
        }}
        shortcut="K"
      />

      <CommandMenuItem
        group="General"
        label={isDesignSystemRoute ? 'Go to Craft' : 'Go to design system'}
        onClick={() => navigate(isDesignSystemRoute ? routes.dashboard : routes.designSystem)}
        shortcut={isDesignSystemRoute ? 'C' : 'D'}
      />

      {isCiMode && (
        <style>
          {`
            * {
              word-break: break-all !important;
              text-transform: none !important;
            }
          `}
        </style>
      )}
    </>
  );
};
