import * as stylex from '@stylexjs/stylex';

import { grayColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  iconWrapper: {
    position: 'relative',
    color: grayColors.gray200,

    // Increased hitbox
    '::after': {
      content: '',
      position: 'absolute',
      inset: -4,
    },
  },
});
