import dayjs from 'dayjs';
import { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useClients } from '~/api/clients';
import { usePreviewQuotation, useSubmitQuotation, useUpdateQuotation } from '~/api/quotations';
import { useUser } from '~/api/user';
import {
  Attachment,
  Button,
  CollapsibleSection,
  DatePicker,
  ErrorModal,
  FloatingActionButton,
  FormControl,
  FormError,
  FormErrorMessage,
  FormHelpText,
  FormLabel,
  FormSelect,
  FormTextArea,
  FormTextInput,
  IconTooltip,
  Modal,
  PageHeader,
  PreviewIFrame,
  RichTextEditor,
  Shortcodes,
  UploadInput,
} from '~/components';
import { Blocker } from '~/components/Functional';
import {
  AutoSaveIndicator,
  CompleteAccountModal,
  FormClientContact,
  Lines,
  LinesTotal,
  ViewConditionModal,
} from '~/components/Templates/InvoiceQuotation';
import { useCalculationData } from '~/hooks/InvoiceQuotationForm/useCalculationData';
import { useDropdownOptions } from '~/hooks/InvoiceQuotationForm/useDropdownOptions';
import { useModal, useModalWithData } from '~/hooks/useModal';
import { routes } from '~/providers/RouterProvider/router.routes';
import { Language } from '~/types/app';
import { isAccountCompleted } from '~/utils/user';

import type { CreateEditQuotationFormType, CreateEditQuotationFormProps as Props } from './types';

import {
  useAttachment,
  useAutoSave,
  useLastQuotationEntrynumber,
  useMailInContactLanguage,
  useQuotationDefaultValues,
  useValidateQuotationEntryNumber,
} from './hooks';
import { QuotationFormCommandMenuItems } from './QuotationFormCommandMenuItems';
import { mapQuotationFormDataToDraftPayload, mapQuotationFormDataToPayload } from './utils';

export const CreateEditQuotationForm = ({ isEdit, quotation }: Props) => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const previewMutation = usePreviewQuotation();
  const updateQuotationMutation = useUpdateQuotation(quotation.id);
  const submitQuotationMutation = useSubmitQuotation(quotation.id);

  // i18n
  const { t } = useTranslation(['common', 'invoices', 'quotations', 'validation']);

  // React router
  const navigate = useNavigate();

  // Utils
  const validateEntryNumber = useValidateQuotationEntryNumber();

  // Local state
  const conditionModal = useModalWithData<number>();
  const confirmationModal = useModal();
  const completeAccountModal = useModal();
  const totalCoTooLowModal = useModal();

  // React hook form
  const defaultValues = useQuotationDefaultValues(quotation);
  const form = useForm<CreateEditQuotationFormType>({ defaultValues });

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = form;

  const isAutoSaving = useAutoSave(quotation.id, control, !isEdit);
  const { data: calculationData, isPending: isCalculationDataPending } = useCalculationData({
    id: quotation.id,
    enabled: isEdit,
    control,
    getValues,
  });

  // Client and contact
  const watchContactId = watch('contactId');
  const selectedContact = clients.flatMap(({ contacts }) => contacts).find((contact) => contact.id === watchContactId) ?? null;
  const contactHasCustomAgreement = (selectedContact?.customAgreements ?? []).length > 0;

  const [contactLanguageChanged, setContactLanguageChanged] = useMailInContactLanguage(selectedContact, (mail) => setValue('mail', mail));

  // Condition
  const watchCondition = watch('condition');

  // Attachment
  const attachment = useAttachment(quotation.id);

  // Constants
  const lastQuotationEntrynumber = useLastQuotationEntrynumber(quotation.id);
  const tomorrow = dayjs().startOf('day').add(1, 'day');

  const dropdownOptions = useDropdownOptions({ customAgreements: selectedContact?.customAgreements ?? [] });

  const onPreview = useCallback(
    () =>
      previewMutation.mutate(
        { id: quotation.id, body: mapQuotationFormDataToDraftPayload(getValues()) },
        { onError: () => toast.error(t('common:error')) },
      ),
    [getValues, previewMutation, quotation.id, t],
  );

  const onModalSubmit = async () => {
    try {
      await updateQuotationMutation.mutateAsync(mapQuotationFormDataToPayload(getValues()));

      await submitQuotationMutation.mutateAsync(undefined, {
        onSuccess: () => navigate(routes.quotations, { state: { blockable: false } }),
      });

      toast.success(t(`quotations:alerts.success${isEdit ? 'Updated' : 'Created'}`));
    } catch {
      toast.error(t('common:error'));
    }
  };

  const onSubmit = handleSubmit(() => {
    if (!isAccountCompleted(user)) return completeAccountModal.open();

    // CO below minimum commisison, meaning FC would be €0
    if (quotation.calculationData.fcExclVat === 0) return totalCoTooLowModal.open();

    confirmationModal.open();
  });

  return (
    <>
      {isEdit && <Blocker isBlocked={isDirty} message={t('quotations:createEdit.blocker.message')} />}

      <PageHeader backRoute={routes.quotations} title={t(`quotations:createEdit.title.${isEdit ? 'edit' : 'create'}`)}>
        <div className="flex flex-col flex-wrap justify-end gap-2 ml-auto sm:flex-row">
          <AutoSaveIndicator isSaving={isAutoSaving} />

          <Button
            extraClasses="mb-2 sm:mb-0 sm:mr-2"
            hasSpinner
            icon="Visibility"
            isLoading={previewMutation.isPending}
            onClick={onPreview}
            type="secondary"
          >
            {t('quotations:createEdit.preview')}
          </Button>

          <Button icon="Send" onClick={onSubmit}>
            {t(`quotations:createEdit.submit.${isEdit ? 'edit' : 'create'}`)}
          </Button>
        </div>
      </PageHeader>

      <FormProvider {...form}>
        <form autoComplete="off" onSubmit={onSubmit}>
          {/* Basic info */}
          <CollapsibleSection
            description={t('quotations:createEdit.basicInfo.description')}
            title={t('quotations:createEdit.basicInfo.title')}
          >
            <div className="grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-4">
              {/* Title */}
              <FormControl control={control} name="title" rules={{ required: true, maxLength: 255, validate: (value) => !!value.trim() }}>
                <FormLabel>{t('quotations:fields.title.label')}</FormLabel>
                <FormTextInput placeholder={t('quotations:fields.title.placeholder')} />
                <FormErrorMessage
                  maxLength={t('validation:maxLength', { attribute: t('quotations:fields.title.label'), max: 255 })}
                  required={t('validation:required')}
                  validate={t('validation:required')}
                />
              </FormControl>

              {/* Entry number */}
              <FormControl
                control={control}
                name="entryNumber"
                rules={{
                  required: true,
                  maxLength: 255,
                  validate: {
                    nonEmpty: (value) => !!value.trim(),
                    unique: (value) => validateEntryNumber(value, quotation.id),
                  },
                }}
              >
                <FormLabel>{t('quotations:fields.entryNumber.label')}</FormLabel>
                <FormTextInput placeholder={t('quotations:fields.entryNumber.placeholder')} />
                {lastQuotationEntrynumber && (
                  <FormHelpText>
                    <Trans
                      components={[<strong className="text-dark-gray" key={null} />]}
                      i18nKey="quotations:fields.entryNumber.last"
                      values={{ entryNumber: lastQuotationEntrynumber }}
                    />
                  </FormHelpText>
                )}
                <FormErrorMessage
                  maxLength={t('validation:maxLength', { attribute: t('quotations:fields.entryNumber.label'), max: 255 })}
                  nonEmpty={t('validation:required')}
                  required={t('validation:required')}
                  unique={t('validation:quotation.entrynumber.invalid')}
                />
              </FormControl>

              {/* Client and contact */}
              {/* z-index is used to prevent the dropdown being painted below the other inputs, as this element has its own stacking context */}
              <div className="lg:row-span-2 z-1">
                <FormClientContact page="quotation" />
              </div>

              {/* Expiration date */}
              <div>
                <label htmlFor="expirationDate">{t('quotations:fields.expirationDate.label')}</label>
                <Controller
                  control={control}
                  name="expirationDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      isInvalid={!!errors.expirationDate}
                      minDate={tomorrow.toDate()}
                      onChange={onChange}
                      placeholder={t('quotations:fields.expirationDate.placeholder')}
                      selected={value ?? undefined}
                    />
                  )}
                  rules={{ required: true, validate: (value) => dayjs(value).isAfter(tomorrow) }}
                />
                {errors.expirationDate && (
                  <FormError>
                    {errors.expirationDate.type === 'required' && t('validation:required')}
                    {errors.expirationDate.type === 'validate' && t('validation:dateTomorrow')}
                  </FormError>
                )}
              </div>

              {/* Brand */}
              {user.brands.length > 0 && (
                <FormControl control={control} name="brandId" rules={{ required: true }}>
                  <FormLabel>{t('quotations:fields.brandId.label')}</FormLabel>
                  <FormSelect options={dropdownOptions.brands} placeholder={t('quotations:fields.brandId.placeholder')} />
                  <FormErrorMessage required={t('validation:required')} />
                </FormControl>
              )}

              {/* Custom agreement */}
              {selectedContact && selectedContact.customAgreements.length > 1 && (
                <div>
                  <FormControl control={control} name="customAgreementId" rules={{ required: true }}>
                    <FormLabel>{t('quotations:fields.customAgreementId.label')}</FormLabel>
                    <FormSelect
                      onAfterChange={({ newValue: customAgreementId }) => {
                        const customAgreement =
                          selectedContact.customAgreements.find(({ id }) => id === customAgreementId) ??
                          selectedContact.customAgreements[0];

                        setValue(
                          'specialConditions',
                          t('invoices:fields.specialConditions.custom', {
                            date: dayjs(customAgreement.date).format('DD-MM-YYYY'),
                            lng: selectedContact.language,
                          }),
                        );
                      }}
                      options={dropdownOptions.customAgreements}
                      placeholder={t('quotations:fields.customAgreementId.placeholder')}
                    />
                    <FormErrorMessage required={t('validation:required')} />
                  </FormControl>
                </div>
              )}
            </div>
          </CollapsibleSection>

          {/* Assignment */}
          <CollapsibleSection
            description={t('quotations:createEdit.assignment.description')}
            title={t('quotations:createEdit.assignment.title')}
          >
            <div className="form-grid-2">
              {/* Introduction */}
              <div>
                <label htmlFor="introduction">{t('quotations:fields.introduction.label')}</label>
                <Controller
                  control={control}
                  name="introduction"
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor
                      isInvalid={!!errors.introduction}
                      onChange={onChange}
                      placeholder={t('quotations:fields.introduction.placeholder')}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />
                {errors.introduction && <FormError>{errors.introduction.type === 'required' && t('validation:required')}</FormError>}
              </div>

              {/* Description */}
              <div>
                <label htmlFor="description">{t('quotations:fields.description.label')}</label>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor
                      isInvalid={!!errors.description}
                      onChange={onChange}
                      placeholder={t('quotations:fields.description.placeholder')}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />
                {errors.description && <FormError>{errors.description.type === 'required' && t('validation:required')}</FormError>}
              </div>
            </div>
          </CollapsibleSection>

          {/* Royalties */}
          <CollapsibleSection
            description={t('quotations:createEdit.royalties.description')}
            title={t('quotations:createEdit.royalties.title')}
          >
            <div className="form-grid-2">
              {/* Condition */}
              <div>
                <FormControl control={control} name="condition" rules={{ required: true }}>
                  <FormLabel>
                    {t('quotations:fields.condition.label')}
                    {watchCondition?.type === 'customCondition' && selectedContact && selectedContact.language !== Language.DUTCH && (
                      <span className="ml-2">
                        <IconTooltip color="text-error-500" iconName="Warning" text={t('quotations:createEdit.royalties.tooltip')} />
                      </span>
                    )}
                  </FormLabel>
                  <FormSelect
                    by={(a, b) => a?.id === b?.id && a?.type === b?.type}
                    disabled={contactHasCustomAgreement}
                    options={dropdownOptions.conditions}
                    placeholder={t('quotations:fields.condition.placeholder')}
                  />
                  <FormErrorMessage required={t('validation:required')} />
                </FormControl>

                {watchCondition?.type === 'condition' && (
                  <Button extraClasses="mt-4" icon="Article" onClick={() => conditionModal.open(watchCondition.id)} type="tertiary">
                    {t('invoices:fields.condition.link')}
                  </Button>
                )}
              </div>

              {/* Special conditions */}
              <FormControl control={control} name="specialConditions">
                <FormLabel optional>{t('quotations:fields.specialConditions.label')}</FormLabel>
                <FormTextArea
                  disabled={contactHasCustomAgreement}
                  placeholder={t('quotations:fields.specialConditions.placeholder')}
                  rows={1}
                />
              </FormControl>
            </div>
          </CollapsibleSection>

          {/* Attachment mail */}
          <CollapsibleSection
            description={t('quotations:createEdit.attachments.description')}
            initialCollapsed
            title={t('quotations:createEdit.attachments.title')}
          >
            {/* Mail */}
            <div className="form-grid-2">
              <div className="col-span-full">
                <label htmlFor="mail">{t('quotations:fields.mail.label')}</label>
                <Controller
                  control={control}
                  name="mail"
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor
                      isInvalid={!!errors.mail}
                      onChange={onChange}
                      placeholder={t('quotations:fields.mail.placeholder')}
                      setShouldUpdateContent={setContactLanguageChanged}
                      shortcodes={[
                        { label: Shortcodes.CLIENTNAME, code: '#CLIENTNAME#' },
                        { label: Shortcodes.QUOTATIONLINK, code: '#QUOTATIONLINK#' },
                        { label: Shortcodes.FREELANCERFIRSTNAME, code: '#FREELANCERFIRSTNAME#' },
                      ]}
                      shouldUpdateContent={contactLanguageChanged}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />
                {errors.mail && <FormError>{errors.mail.type === 'required' && t('validation:required')}</FormError>}
              </div>

              {/* Attachment */}
              <div>
                <label htmlFor="mail">{t('quotations:fields.attachment.label')}</label>
                {attachment.exists ? (
                  <Attachment
                    isLoading={attachment.isRemoving}
                    onDeleteClick={attachment.remove}
                    onViewClick={attachment.open}
                    text={t('quotations:createEdit.attachments.attachment')}
                  />
                ) : (
                  <UploadInput
                    fileTypes="application/pdf"
                    isLoading={attachment.isUploading}
                    maxFileSizeInMegaByte={8}
                    onChange={attachment.upload}
                    smallText={t('invoices:createEdit.attachments.smalltext')}
                  />
                )}
              </div>
            </div>
          </CollapsibleSection>

          {/* Quotation lines */}
          <CollapsibleSection description={t('quotations:createEdit.lines.description')} title={t('quotations:createEdit.lines.title')}>
            <Lines type="quotation" />
            <LinesTotal
              calculationData={calculationData ?? quotation.calculationData}
              isLoading={isAutoSaving || isCalculationDataPending}
            />
          </CollapsibleSection>
        </form>

        <QuotationFormCommandMenuItems />
      </FormProvider>

      {/* FAB */}
      <FloatingActionButton
        isMultiple
        options={[
          {
            text: t(`quotations:createEdit.submit.${isEdit ? 'edit' : 'create'}`),
            onClick: onSubmit,
            icon: 'Send',
          },
          {
            text: t('quotations:createEdit.preview'),
            onClick: onPreview,
            icon: 'Visibility',
            style: 'secondary',
          },
        ]}
      />

      {/* Confirmation modal */}
      {confirmationModal.isOpen && (
        <Modal onClose={confirmationModal.close}>
          <Modal.Title>{t('quotations:createEdit.confirmation.title')}</Modal.Title>

          <p>{t('quotations:createEdit.confirmation.description')}</p>

          <Modal.Actions>
            <Button
              hasSpinner
              icon="Send"
              isLoading={isAutoSaving || updateQuotationMutation.isPending || submitQuotationMutation.isPending}
              onClick={() => onModalSubmit()}
            >
              {t(`quotations:createEdit.confirmation.submit.${isEdit ? 'edit' : 'create'}`)}
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {/* Complete account modal */}
      {completeAccountModal.isOpen && (
        <CompleteAccountModal
          onClose={completeAccountModal.close}
          onComplete={() => {
            completeAccountModal.close();
            confirmationModal.open();
          }}
        />
      )}

      {/* Preview modal */}
      {previewMutation.data && (
        <Modal onClose={previewMutation.reset} variant="preview">
          <PreviewIFrame srcDoc={previewMutation.data} />
        </Modal>
      )}

      {/* View condition modal */}
      {conditionModal.isOpen && <ViewConditionModal conditionId={conditionModal.data} onClose={conditionModal.close} />}

      {/* CO too low modal */}
      {totalCoTooLowModal.isOpen && (
        <ErrorModal
          buttonText={t('invoices:createEdit.coTooLowModal.dismiss')}
          onClose={totalCoTooLowModal.close}
          title={t('invoices:createEdit.coTooLowModal.title')}
        >
          <p>{t('invoices:createEdit.coTooLowModal.description')}</p>
        </ErrorModal>
      )}
    </>
  );
};
