import * as stylex from '@stylexjs/stylex';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from '~/components/SVG';
import { LinkButton, Logo, Text } from '~/components/UI';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { routes } from '~/providers/RouterProvider/router.routes';
import { darkBackgroundTheme } from '~/styles/themes';
import { groupObjectsBy } from '~/utils/objects';

import { CreativeShelterGift } from '../../SVG/Illustration/referrals/CreativeShelterGift';
import { useNavigationMenuItems } from '../hooks';
import { NavigationBackdrop } from '../NavigationBackdrop/NavigationBackdrop';
import styles from './NavigationDesktop.module.scss';
import { styles as stylexStyles } from './NavigationDesktop.styles';

export const NavigationDesktop = () => {
  const navigationMenuItems = useNavigationMenuItems();
  const groupedItems = useMemo(() => groupObjectsBy(navigationMenuItems, ({ group }) => group), [navigationMenuItems]);

  const { t } = useTranslation(['common', 'invitations']);

  const { pathname } = useLocation();
  const screen = useScreenWidth();

  // Sidebar
  const [sidebarPreference, setSidebarPreference] = useCraftLocalStorage('sidebar');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(screen.isLarge && sidebarPreference === 'expanded');

  useEffect(() => {
    if (screen.isLarge && sidebarPreference === 'expanded') setIsSidebarExpanded(true);
  }, [screen.isLarge, sidebarPreference]);

  useEffect(() => {
    if (!screen.isLarge) setIsSidebarExpanded(false);
  }, [screen.isLarge]);

  if (screen.isMobile) return null;

  return (
    <>
      <NavigationBackdrop isShown={!screen.isLarge && isSidebarExpanded} onClick={() => setIsSidebarExpanded(false)} />

      <aside className={classNames(styles.Column, !isSidebarExpanded && styles.Collapsed)}>
        <div className={styles.Wrapper}>
          <Link to={routes.dashboard}>
            <Logo className={styles.Logo} variant={isSidebarExpanded ? 'horizontal' : 'vertical'} />
          </Link>

          <nav className={styles.Nav}>
            <ul className={styles.NavList}>
              {Object.entries(groupedItems).map(([group, items]) => (
                <Fragment key={group}>
                  {isSidebarExpanded && (
                    <span className={styles.NavLabel}>
                      {group === 'invoicing' ? t('common:sidebar.groupInvoicing') : t('common:sidebar.groupSocial')}
                    </span>
                  )}

                  {items.map((item) => (
                    <Link key={item.text} to={item.route}>
                      <li
                        className={classNames(styles.NavItem, pathname === item.route && styles.Active)}
                        data-pf-id={`navigation-${item.route.slice(1)}`}
                      >
                        <div className={styles.IconWrapper}>
                          <Icon name={item.iconName} size={isSidebarExpanded ? 26 : 30} />
                          {!isSidebarExpanded && item.newFeature && <NewFeatureIcon />}
                        </div>

                        {isSidebarExpanded && (
                          <span className={styles.NavText}>
                            {item.text}
                            {item.newFeature && <NewFeatureIcon />}
                          </span>
                        )}
                      </li>
                    </Link>
                  ))}
                </Fragment>
              ))}
            </ul>
          </nav>

          <div {...stylex.props(stylexStyles.invitationButtonWrapper, darkBackgroundTheme)}>
            <LinkButton
              styles={[stylexStyles.invitationButton, !isSidebarExpanded && stylexStyles.invitationButtonCollapsed]}
              to={routes.settingsInvitations}
            >
              <CreativeShelterGift
                styles={[stylexStyles.invitationIllustration, !isSidebarExpanded && stylexStyles.invitationIllustrationCollapsed]}
              />
              <Text noLineHeight styles={!isSidebarExpanded && stylexStyles.invitationButtonTextCollapsed} weight="medium">
                {t('invitations:sidebar.cta')}
              </Text>
            </LinkButton>
          </div>

          <button
            className={styles.ExpandCollapseButton}
            onClick={() => {
              setIsSidebarExpanded((prev) => !prev);
              if (screen.isLarge) setSidebarPreference(isSidebarExpanded ? 'collapsed' : 'expanded');
            }}
          >
            <Icon name={isSidebarExpanded ? 'ChevronLeft' : 'ChevronRight'} />
            <span className={styles.ExpandCollapseButton__Text}>{isSidebarExpanded && t('common:collapseMenu')}</span>
          </button>
        </div>
      </aside>
    </>
  );
};

const NewFeatureIcon = () => {
  return (
    <span className={styles.NewFeatureIcon}>
      <Icon name="NewReleases" size={18} />
    </span>
  );
};
