import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductFruits as BaseProductFruits } from 'react-product-fruits';
import { useNavigate } from 'react-router-dom';

import { useUser } from '~/api/user';
import { env } from '~/constants/env';

type Props = {
  workspaceCode: string;
};

export const ProductFruits = ({ workspaceCode }: Props) => {
  const { data: user } = useUser();

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const productFruitsUser = useMemo(() => {
    const primaryActivity = user.activities.find((activity) => activity.isPrimaryActivity);

    return {
      username: user.email,
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      signUpAt: user.registeredAt,
      props: {
        primaryActivity: primaryActivity ? t(`common:activities.${primaryActivity.id}.label`) : '',
        secondaryActivities: user.activities
          .filter((activity) => !activity.isPrimaryActivity)
          .map(({ id }) => t(`common:activities.${id}.label`)),
        invitorDiscounts: user.invitorDiscounts.filter(({ isActive }) => isActive).length,
        invitations: user.invitations.filter(({ status }) => status !== 'declined').length,
        isCraftCommunityEnabled: user.communityProfile.enabled,
        isNpsShown: user.showNps,
        hasApprovedInvoice: user.hasApprovedInvoice,
      },
    };
  }, [
    t,
    user.activities,
    user.communityProfile.enabled,
    user.email,
    user.firstName,
    user.hasApprovedInvoice,
    user.invitations,
    user.invitorDiscounts,
    user.lastName,
    user.registeredAt,
    user.showNps,
  ]);

  if (env.IS_LOCAL_DEV_ENV) return null;

  return (
    <BaseProductFruits
      config={{
        customNavigation: {
          use: true,
          navigate: (location) => (location.startsWith('/') ? navigate(location) : window.open(location, '_self')),
          onGet: () => window.location.href,
        },
      }}
      language={user.lang}
      user={productFruitsUser}
      workspaceCode={workspaceCode}
    />
  );
};
