import * as stylex from '@stylexjs/stylex';

import { fontWeight, radius, spacing, systemColors, themeColors } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

const isHovered = ':is([data-hover])';
const isFocused = ':is([data-focus])';

export const styles = stylex.create({
  option: {
    display: 'flex',
    alignItems: 'center',
  },

  optionInButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  optionInList: {
    borderRadius: radius.xxxsmall,
    color: {
      default: systemColors.textDark,
      [isHovered]: systemColors.textBlack,
      [isFocused]: systemColors.textBlack,
    },
    backgroundColor: {
      default: null,
      [isHovered]: themeColors.primary050,
      [isFocused]: themeColors.primary050,
    },
    cursor: 'pointer',
  },

  spacingInList: {
    paddingInline: spacing.xsmall,
    paddingBlock: spacing.xxsmall,
  },

  selected: {
    fontWeight: fontWeight.semiBold,
    cursor: 'default',
  },

  disabled: {
    color: formColors.disabledText,
    cursor: 'not-allowed',
  },
});
