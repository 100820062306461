import * as stylex from '@stylexjs/stylex';

import type {
  MediaQueryMobileAndBelow,
  MediaQuerySmallMobileAndBelow,
  MediaQueryTablet,
  MediaQueryTabletAndBelow,
} from '~/styles/mediaQueries';

import { grayColors, radius, spacing, themeColors } from '~/styles/tokens.stylex';

const Tablet: MediaQueryTablet = '@media (768px <= width < 1024px)';
const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';
const MobileAndBelow: MediaQueryMobileAndBelow = '@media (width < 768px)';
const SmallMobileAndBelow: MediaQuerySmallMobileAndBelow = '@media (width < 640px)';

export const styles = stylex.create({
  card: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: {
      default: '2fr 5fr',
      [Tablet]: '1fr 3fr',
      [MobileAndBelow]: '1fr',
    },
    gridTemplateAreas: {
      default: `"illustration content"`,
      [MobileAndBelow]: `"content"`,
    },
    gap: {
      default: spacing.normal,
      [TabletAndBelow]: spacing.xsmall,
    },
    padding: spacing.medium,
    borderRadius: radius.xsmall,
    backgroundColor: themeColors.primary500,
  },

  illustrationWrapper: {
    gridArea: 'illustration',
    display: {
      default: null,
      [MobileAndBelow]: 'none',
    },
    position: 'relative',
  },

  illustration: {
    position: 'absolute',
    top: {
      default: -20,
      [TabletAndBelow]: 0,
    },
    maxHeight: 200,
    insetInline: 0,
    marginInline: 'auto',
  },

  content: {
    gridArea: 'content',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  title: {
    maxWidth: {
      default: 400,
      [TabletAndBelow]: 'unset',
    },
  },

  buttons: {
    display: 'flex',
    columnGap: spacing.xxsmall,
    rowGap: spacing.xxxsmall,
    flexWrap: 'wrap',
  },

  shareButton: {
    color: {
      default: grayColors.white,
      ':hover': grayColors.gray800,
    },
    borderColor: {
      default: grayColors.white,
      ':hover': grayColors.gray800,
    },
    backgroundColor: {
      default: 'transparent',
      ':hover': grayColors.white,
    },
  },

  linkButtons: {
    display: 'flex',
    gap: {
      default: spacing.xxxsmall,
      [SmallMobileAndBelow]: spacing.none,
    },
  },

  linkButton: {
    justifyContent: 'center',
    alignItems: 'center',
    color: {
      default: grayColors.white,
      ':hover': themeColors.primary300,
    },
  },

  paperPlane3: {
    position: 'absolute',
    top: -25,
    right: 40,
    width: 64,
  },

  paperPlane2: {
    position: 'absolute',
    bottom: {
      default: -20,
      [MobileAndBelow]: -30,
    },
    right: {
      default: '25%',
      [MobileAndBelow]: '70%',
    },
    width: 40,
  },
});
