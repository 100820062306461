import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { grayColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

export const styles = stylex.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
  },

  benefitCards: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: {
      default: 'column',
      [TabletAndBelow]: 'row',
    },
    columnGap: spacing.medium,
    rowGap: spacing.normal,
  },

  benefitCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
    paddingInline: spacing.large,
    paddingBlock: spacing.normal,
    backgroundColor: grayColors.gray025,
    borderRadius: radius.xxsmall,
    position: 'relative',
  },

  benefitCardInvitor: {
    paddingInlineStart: '35%',
  },

  benefitCardInvitee: {
    paddingInlineEnd: '55%',
  },

  illustrationWrapper: {
    position: 'absolute',
    bottom: 0,
    minWidth: 100,
    display: 'flex',
  },

  illustrationInvitorWrapper: {
    width: '32.5%',
    left: '-2.5%',
  },

  illustrationInvitor: {
    marginInline: 'auto',
    maxHeight: {
      default: 160,
      [TabletAndBelow]: 140,
    },
    transform: 'translateY(3%)',
  },

  illustrationInviteeWrapper: {
    width: '55%',
    right: '2.5%',
  },

  illustrationInvitee: {
    marginInlineStart: 'auto',
    maxHeight: {
      default: 160,
      [TabletAndBelow]: 140,
    },
    transform: 'translateY(27%)',
  },

  disclaimer: {
    marginBlockStart: {
      default: null,
      [TabletAndBelow]: spacing.xxxsmall, // Prevent being covered by the illustration
    },
    maxWidth: '75ch',
    color: systemColors.textMuted,
  },
});
