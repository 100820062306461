import type { SVGProps } from 'react';

import { match } from 'ts-pattern';

import LogoHorizontal from '~/assets/logos/craft-horizontal.svg?react';
import LogoSymbol from '~/assets/logos/craft-symbol.svg?react';
import LogoVertical from '~/assets/logos/craft-vertical.svg?react';

// TODO: Move to assets as React components

type Props = SVGProps<SVGSVGElement> & {
  variant: 'horizontal' | 'vertical' | 'symbol';
};
export const Logo = ({ variant, ...delegated }: Props) => {
  const Component = match(variant)
    .with('horizontal', () => LogoHorizontal)
    .with('vertical', () => LogoVertical)
    .with('symbol', () => LogoSymbol)
    .exhaustive();

  return <Component {...delegated} />;
};
