import * as stylex from '@stylexjs/stylex';

import { grayColors, spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  fullscreen: {
    position: 'fixed',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing.normal,
    backgroundColor: grayColors.white,
  },

  message: {
    textAlign: 'center',
  },
});
