import type { FieldErrors } from 'react-hook-form';

import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { InvoiceQuotationFormEntryLine, InvoiceQuotationFormTextLine } from '~/types/invoiceQuotation';

import { FormError } from '~/components';
import { Icon } from '~/components/SVG';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';

import type { LineDesktopProps } from './types';

import linesStyles from '../Lines.module.scss';
import { useSectionContext } from '../Section/context';
import { useLineContext } from '../SectionLines/context';
import { FormActivityType } from './FormActivityType/FormActivityType';
import { FormDescription } from './FormDescription/FormDescription';
import { FormDiscount } from './FormDiscount/FormDiscount';
import { FormPrice } from './FormPrice/FormPrice';
import { FormProductName } from './FormProductName/FormProductName';
import { FormQuantity } from './FormQuantity/FormQuantity';
import { useIsFreeLine, useLineTotal, useShouldHaveTopBorder } from './hooks';
import { LineActions } from './LineActions/LineActions';
import styles from './LineDesktop.module.scss';

export const LineDesktop = () => {
  const { isDefaultSection, sectionIndex } = useSectionContext();
  const { line, lineIndex } = useLineContext();
  const indented = !isDefaultSection;
  const shouldHaveBorderTop = useShouldHaveTopBorder(sectionIndex, lineIndex);

  return (
    <Draggable draggableId={`${line.id}`} index={lineIndex}>
      {(provided, snapshot) => (
        <tr
          className={classNames(
            styles.Line,
            indented && styles.Indented,
            snapshot.isDragging && styles.IsDragging,
            shouldHaveBorderTop && styles.BorderTop,
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          data-pf-id={`line-${lineIndex}`}
        >
          {line.type === 'entry' ? (
            <EntryLineDesktop dragHandleProps={provided.dragHandleProps} />
          ) : (
            <TextLineDesktop dragHandleProps={provided.dragHandleProps} />
          )}
        </tr>
      )}
    </Draggable>
  );
};

const EntryLineDesktop = ({ dragHandleProps }: LineDesktopProps) => {
  const {
    formState: { errors },
    watch,
  } = useInvoiceQuotationFormContext();

  const { isDefaultSection, sectionIndex } = useSectionContext();
  const indented = !isDefaultSection;
  const { line, lineFieldName, lineIndex } = useLineContext();

  const { formatCurrency, formatDecimal, formatPercentage } = useIntl();
  const { t } = useTranslation(['lines', 'validation']);

  const lineErrors =
    (errors.sections?.[sectionIndex]?.lines?.[lineIndex] as FieldErrors<InvoiceQuotationFormEntryLine> | undefined) ?? null;
  const watchDiscountType = watch(`${lineFieldName}.discountType`);

  const { discountedTotal, total } = useLineTotal(lineFieldName);
  const isFreeLine = useIsFreeLine(lineFieldName);
  const isProductLine = line.type === 'entry' && !!line.productName;

  return (
    <>
      <td className={classNames(linesStyles.NestedRow, lineErrors && styles.IsInvalid)}>
        <div className={classNames(styles.DragHandler, indented && styles.Indented)}>
          <div {...dragHandleProps}>
            <Icon name="DragHandle" />
          </div>
        </div>

        <div className={classNames(styles.Description, indented && styles.Indented, isProductLine && styles.IsProduct)}>
          {isProductLine && <FormProductName />}
          <FormDescription />
        </div>

        <div className={classNames(styles.PriceAmount, watchDiscountType !== null && styles.WithDiscount)}>
          <FormQuantity />
          <FormPrice />
          <FormDiscount />
          <div className={styles.ActivityType}>
            <FormActivityType />
          </div>
        </div>

        <div className={styles.Total}>
          <span className={classNames(watchDiscountType !== null && styles.Discounted)}>{formatCurrency(total)}</span>
          {watchDiscountType !== null && <span>{isFreeLine ? t('lines:discount.free') : formatCurrency(discountedTotal)}</span>}
        </div>

        <div className={styles.Actions}>
          <LineActions />
        </div>
      </td>

      {lineErrors && (
        <td className={classNames(linesStyles.NestedRow, styles.ErrorRow)}>
          {lineErrors.description && (
            <div className={classNames(styles.DescriptionError, indented && styles.Indented)}>
              <FormError>
                {lineErrors.description.type === 'required' && t('validation:required')}
                {lineErrors.description.type === 'validate' && t('validation:required')}
                {lineErrors.description.type === 'maxLength' &&
                  t('validation:maxLength', {
                    attribute: isProductLine ? t('lines:description.placeholderProduct') : t('lines:description.header'),
                    max: 255,
                  })}
              </FormError>
            </div>
          )}
          {(lineErrors.price || lineErrors.discountValue || lineErrors.quantity || lineErrors.creativeWork) && (
            <div className={classNames(styles.PriceAmountError, indented && styles.Indented)}>
              {lineErrors.quantity && (
                <FormError>
                  {lineErrors.quantity.type === 'required' && t('validation:requiredAttribute', { attribute: t('lines:quantity.header') })}
                  {lineErrors.quantity.type === 'min' &&
                    t('validation:min', { attribute: t('lines:quantity.header'), min: formatDecimal(0.01) })}
                  {lineErrors.quantity.type === 'max' &&
                    t('validation:max', { attribute: t('lines:quantity.header'), max: formatDecimal(100_000) })}
                </FormError>
              )}
              {lineErrors.price && (
                <FormError>
                  {lineErrors.price.type === 'required' && t('validation:requiredAttribute', { attribute: t('lines:price.header') })}
                  {lineErrors.price.type === 'min' &&
                    t('validation:min', { attribute: t('lines:price.header'), min: formatCurrency(0.01) })}
                  {lineErrors.price.type === 'max' &&
                    t('validation:max', { attribute: t('lines:price.header'), max: formatCurrency(100_000) })}
                </FormError>
              )}
              {lineErrors.discountValue && (
                <FormError>
                  {lineErrors.discountValue.type === 'required' &&
                    t('validation:requiredAttribute', { attribute: t('lines:discount.header') })}
                  {lineErrors.discountValue.type === 'min' &&
                    t('validation:min', {
                      attribute: t('lines:discount.header'),
                      min: watchDiscountType === 'percentage' ? formatPercentage(0.01) : formatCurrency(0.01),
                    })}
                  {lineErrors.discountValue.type === 'max' &&
                    (watchDiscountType === 'percentage'
                      ? t('validation:max', { attribute: t('lines:discount.header'), max: formatPercentage(1) })
                      : t('validation:maxDiscount'))}
                </FormError>
              )}
              {lineErrors.creativeWork && <FormError>{lineErrors.creativeWork.type === 'required' && t('validation:required')}</FormError>}
            </div>
          )}
        </td>
      )}
    </>
  );
};

const TextLineDesktop = ({ dragHandleProps }: LineDesktopProps) => {
  const {
    formState: { errors },
  } = useInvoiceQuotationFormContext();

  const { isDefaultSection, sectionIndex } = useSectionContext();
  const indented = !isDefaultSection;
  const { lineIndex } = useLineContext();

  const { t } = useTranslation(['lines', 'validation']);

  const lineErrors = (errors.sections?.[sectionIndex]?.lines?.[lineIndex] as FieldErrors<InvoiceQuotationFormTextLine> | undefined) ?? null;

  return (
    <>
      <td className={classNames(linesStyles.NestedRow, styles.TextRow, lineErrors && styles.IsInvalid)}>
        <div className={classNames(styles.DragHandler, indented && styles.Indented)}>
          <div {...dragHandleProps}>
            <Icon name="DragHandle" />
          </div>
        </div>

        <div className={classNames(styles.Description, indented && styles.Indented)}>
          <FormDescription />
        </div>

        <div />

        <div className={styles.Actions}>
          <LineActions />
        </div>
      </td>

      {lineErrors?.description && (
        <td className={classNames(linesStyles.NestedRow, styles.ErrorRow)}>
          <div className={classNames(styles.DescriptionError, indented && styles.Indented)}>
            <FormError>
              {lineErrors.description.type === 'required' && t('validation:required')}
              {lineErrors.description.type === 'validate' && t('validation:required')}
              {lineErrors.description.type === 'maxLength' && t('validation:maxLength', { attribute: t('lines:textLine'), max: 255 })}
            </FormError>
          </div>
        </td>
      )}
    </>
  );
};
