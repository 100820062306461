import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, grayColors, lineHeight, spacing, systemColors } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

const isFocused = ':is([data-focus])';
const isDisabled = ':is([data-disabled])';

export const styles = stylex.create({
  wrapper: {
    position: 'relative',
  },

  textarea: {
    width: '100%',
    height: 'auto',
    minHeight: 40,
    // Textarea height is determined by the number of rows.
    // Each row (normally) has a height of 24px (16px font size * 1.5 line height).
    // This results in a single-row textarea to have a height of 42px.
    // To ensure the height is 40px like all other inputs, we subtract 2px from the padding.
    paddingBlock: `calc(${spacing.xxsmall} - 1px)`,
    color: {
      default: systemColors.textDark,
      [isDisabled]: formColors.disabledText,
    },
    borderColor: {
      default: systemColors.borderLight,
      [isFocused]: formColors.focusBorder,
    },
    backgroundColor: {
      default: grayColors.white,
      [isDisabled]: formColors.disabledBackground,
    },
    fontSize: fontSize.regular,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.normal,
    cursor: {
      default: null,
      [isDisabled]: 'not-allowed',
    },

    '::placeholder': {
      color: formColors.placeholderText,
      opacity: 1,
    },
  },

  invalidIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: formColors.invalid,
  },

  invalid: {
    paddingInlineStart: 40,
    borderColor: formColors.invalid,
  },

  // Resize

  both: {
    resize: 'both',
  },

  horizontal: {
    resize: 'horizontal',
  },

  vertical: {
    resize: 'vertical',
  },

  none: {
    resize: 'none',
  },
});
