import * as stylex from '@stylexjs/stylex';

import { fontSize, grayColors, lineHeight, radius, spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  tooltip: {
    maxWidth: 'min(28rem, 90vw)',
    position: 'relative',
    padding: spacing.xsmall,
    borderRadius: radius.xxxsmall,
    color: grayColors.white,
    backgroundColor: grayColors.gray700,
    fontSize: fontSize.small,
    lineHeight: lineHeight.snug,
    textAlign: 'center',
    textWrap: 'balance',
    overflowWrap: 'break-word',
    isolation: 'isolate',
  },

  arrow: {
    width: 12,
    height: 12,
    position: 'absolute',
    borderTopLeftRadius: 2,
    borderBottomRightRadius: 2,
    backgroundColor: grayColors.gray700,
    transform: 'rotate(45deg)',
    pointerEvents: 'none',
    zIndex: -1,
  },

  arrowHorizontal: {
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
  },
});
