import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { animationSpeed, fontSize, radius, spacing, systemColors, themeColors } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

export const styles = stylex.create({
  footer: {
    gridArea: 'footer',
    marginInline: {
      default: spacing.xxlarge,
      [TabletAndBelow]: spacing.none,
    },
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.xxsmall,
    justifyContent: 'space-between',
    alignItems: 'center',
    color: systemColors.textMuted,
    fontSize: fontSize.tiny,
  },

  socialsList: {
    display: 'flex',
    gap: spacing.xsmall,
  },

  socialLink: {
    position: 'relative',
    color: {
      default: systemColors.textMuted,
      ':hover': themeColors.primary400,
      ':focus-visible': themeColors.primary400,
    },
    borderRadius: radius.xxxsmall, // Rounding of the outline
    outlineStyle: {
      default: 'none',
      ':focus-visible': 'solid',
    },
    outlineWidth: '1px',
    outlineOffset: '2px',
    outlineColor: systemColors.outline,
    transitionProperty: 'color',
    transitionDuration: animationSpeed.fast,

    // Increased hitbox
    '::after': {
      content: '',
      position: 'absolute',
      inset: -6,
    },
  },
});
