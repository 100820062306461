import type { Invoice } from '~/types/invoice';

import { AdvantageSimulationModal } from '~/components/Templates/InvoiceQuotation';
import { Logo } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';
import { useModal } from '~/hooks/useModal';

export const InvoiceTableAdvantageCell = ({ advantage, invoice }: { advantage: number; invoice: Invoice }) => {
  const advantageSimulationModal = useModal();

  const { formatCurrency } = useIntl();

  return (
    <>
      <div
        className="flex gap-x-2 items-baseline text-primary-400 hover:text-primary-300 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          advantageSimulationModal.open();
        }}
      >
        {formatCurrency(advantage)}
        <Logo className="w-5 shrink-0" variant="symbol" />
      </div>

      {advantageSimulationModal.isOpen && (
        <AdvantageSimulationModal calculationData={invoice.calculationData} onClose={advantageSimulationModal.close} />
      )}
    </>
  );
};
