import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Client } from '~/api/clients/types';

import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { useContactsTableColumns } from './ContactsTable.hooks';

export const ContactsTable = ({ client }: { client: Client }) => {
  const data = useMemo(() => client.contacts.filter(({ deleted }) => !deleted), [client.contacts]);
  const columns = useContactsTableColumns();
  const [table, Table] = useTable({ data, columns });

  const navigate = useNavigate();

  return (
    <Table.Root table={table}>
      <Table.Grid>
        <Table.Header />
        <Table.Body>
          {(row) => (
            <Table.Row onClick={(contact) => navigate(r(routes.editContact, { clientId: client.id, contactId: contact.id }))} row={row} />
          )}
        </Table.Body>
      </Table.Grid>
    </Table.Root>
  );
};
