import * as stylex from '@stylexjs/stylex';

import type { MediaQuerySmallMobile, MediaQueryTinyMobileAndBelow } from '~/styles/mediaQueries';

import { grayColors, radius, spacing } from '~/styles/tokens.stylex';

const SmallMobile: MediaQuerySmallMobile = '@media (400px <= width < 640px)';
const TinyMobileAndBelow: MediaQueryTinyMobileAndBelow = '@media (width < 400px)';

export const styles = stylex.create({
  dialog: {
    position: 'relative',
  },

  backdrop: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'hsla(0 0% 0% / 30%)',
  },

  fixedContainer: {
    position: 'fixed',
    inset: 0,
    padding: {
      default: spacing.medium,
      [SmallMobile]: spacing.xsmall,
      [TinyMobileAndBelow]: spacing.xxxsmall,
    },
    overflowY: 'auto',
  },

  scrollContainer: {
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  panel: {
    width: '100%',
    padding: spacing.medium,
    borderRadius: radius.xxsmall,
    backgroundColor: grayColors.white,
  },

  'panel-normal': {
    maxWidth: 600,
  },

  'panel-small': {
    maxWidth: 400,
  },

  header: {
    marginBlockEnd: spacing.normal,
    display: 'flex',
    gap: spacing.xxsmall,
    justifyContent: 'space-between',
  },

  titleAndIcon: {
    display: 'flex',
    gap: spacing.xsmall,
    alignItems: 'center',
  },

  closeButton: {
    marginTop: `calc(${spacing.xsmall} * -1)`,
    marginRight: `calc(${spacing.xsmall} * -1)`,
    outlineOffset: '0px',
  },
});
