import * as stylex from '@stylexjs/stylex';

import { grayColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  tooltipLink: {
    color: {
      default: grayColors.white,
      ':hover': grayColors.gray100,
    },
  },
});
