import * as stylex from '@stylexjs/stylex';

import type { MediaQueryMobileAndBelow } from '~/styles/mediaQueries';

import { grayColors, spacing } from '~/styles/tokens.stylex';

const MobileAndBelow: MediaQueryMobileAndBelow = '@media (width < 768px)';

export const styles = stylex.create({
  centerContainer: {
    minWidth: '100%',
    minHeight: '100%',
    padding: spacing.xlarge,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.normal,
    backgroundColor: grayColors.gray025,
  },

  contentWrapper: {
    maxWidth: {
      default: 600,
      [MobileAndBelow]: 400,
    },
    display: 'flex',
    flexDirection: {
      default: 'row',
      [MobileAndBelow]: 'column-reverse',
    },
    gap: {
      default: spacing.xxxlarge,
      [MobileAndBelow]: spacing.large,
    },
  },

  coffee: {
    height: {
      default: 240,
      [MobileAndBelow]: 200,
    },
    alignSelf: {
      default: null,
      [MobileAndBelow]: 'end',
    },
  },

  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
  },
});
