import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, grayColors, lineHeight, radius, spacing, systemColors, themeColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  accountantForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
    padding: spacing.normal,
    borderRadius: radius.xxsmall,
    color: systemColors.textDark,
    backgroundColor: themeColors.primary500,
  },

  label: {
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.tight,
  },

  inputWrapper: {
    marginBlockStart: spacing.xxsmall,
  },

  grow: {
    flexGrow: 1,
  },

  validationError: {
    color: grayColors.white,
  },
});
