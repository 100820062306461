export const dashboard = {
  charts: {
    labels: {
      commission: 'Vergoeding Creative Shelter',
      copyright: 'Deel auteursrechten',
      currentQ: 'Lopend kwartaal (K{{ quarter }})',
      expired: 'Vervallen',
      nettoBenefit: 'Netto voordeel',
      notExpired: 'Niet vervallen',
      paid: 'Betaalde facturen',
      performance: 'Deel beroepsinkomen',
      previousQ: 'Vorige kwartalen',
      total: 'Totaal aantal facturen',
      unpaid: 'Onbetaalde facturen',
    },
    netAdvantage: 'Netto voordeel',
    overview: 'Overzicht',
    table: {
      amount: 'Aantal',
      description: 'Beschrijving',
      value: 'Bedrag',
    },
    title: 'Jaaroverzicht',
    totalBilled: 'Totaal gefactureerd',
  },
  frameworkAgreement: {
    checkboxError: 'Om aan de slag te gaan, moet je akkoord gaan met de algemeen voorwaarden.',
    checkboxLabel: 'Ik ga akkoord met de algemene voorwaarden.',
    subtitle: 'Geen kleine of verborgen lettertjes, alleen maar duidelijke afspraken.',
    title: 'Lees de algemene voorwaarden',
  },
  header: {
    actionBlock: {
      button: 'Factuur aanmaken',
      dropdown: {
        client: 'Klant aanmaken',
        creditnote: 'Creditnota aanmaken',
        quotation: 'Offerte aanmaken',
      },
    },
    description: 'Welkom terug op je Creative Shelter-dashboard!',
  },
  progression: {
    hide: 'verbergen',
    optional: {
      accountant: 'Stuur je documenten automatisch door!',
      community: 'Word lid van de Craft Community!',
      completed: 'Je maakt gebruik van alle (optionele) uitbreidingsmogelijkheden',
      invite: 'Nodig iemand uit om het platform te gebruiken',
      plan: 'Upgrade jouw tariefplan',
      theme: 'Personaliseer je applicatie',
      title: 'Breid je account uit',
    },
    required: {
      completed: 'Je Creative Shelter-account is volledig up-to-date!',
      create: 'Creëer en activeer je account',
      iban: 'Link je IBAN aan je Creative Shelter-account',
      signAddendum2024: 'Onderteken het addendum aan je raamovereenkomst met Creative Shelter',
      signContract: 'Teken je raamovereenkomst',
      signContractModalTitle: 'Teken jouw Creative Shelter raamovereenkomst',
      title: 'Vervolledig je Creative Shelter-account',
      vatRegulation: 'Duid je btw-status aan',
    },
  },
  royaltiesReformAnnouncement: {
    messageIt:
      "<p>Een nieuw jaar, en voor het eerst ook een addendum aan je raamovereenkomst. Terug te vinden onder dashboard, bij 'Vervolledig je Creative Shelter-account'. De verdeelsleutel is vanaf dit jaar 60/40, beroepsinkomsten/auteursrechten. Eenmaal getekend, kan je factureren.\n<br></p>\n<p>Computerprogramma prestaties, die zullen vanaf nu zonder een auteursvergoeding zijn. Je kan deze gebruiken, maar een fiscaal voordeel haal je er niet meer uit.\n<br></p>\n<p>En de plannen zijn eens door elkaar geschud! Zo bleven enkel het Premium- en Classic-tariefplan over. Zo krijg je meer voor dezelfde vergoeding, betaal je minder, voor hetzelfde. De veranderingen en nieuwe inhoud <0>vind je hier.</0></p>",
    messageNonIt:
      "<p>Een nieuw jaar, en voor het eerst ook een addendum aan je raamovereenkomst. Terug te vinden onder dashboard, bij 'Vervolledig je Creative Shelter-account'. De verdeelsleutel is vanaf dit jaar 60/40, beroepsinkomsten/auteursrechten. Eenmaal getekend, kan je factureren.\n<br></p>\n<p>En de plannen zijn eens door elkaar geschud! Zo bleven enkel het Premium- en Classic-tariefplan over. Zo krijg je meer voor dezelfde vergoeding, betaal je minder, voor hetzelfde. De veranderingen en nieuwe inhoud <0>vind je hier.</0></p>",
    titleIt: '<span className="text-secondary-500">2024</span>, wat verandert er nu precies?',
    titleNonIt: '<span className="text-secondary-500">2024</span>, wat verandert er nu precies?',
  },
  stats: {
    copyrightAverage: {
      label: 'Gemiddelde auteursrechten',
      tooltip:
        'Vierjaarlijks gemiddelde dat je ontving als auteursvergoeding, via Creative Shelter.<br>Waarom dit belangrijk is en hoe dit berekend wordt, <a href="https://knowledge.creativeshelter.be/hoe-wordt-jouw-gemiddelde-auteursrechten-berekend-op-het-dashboard" target="_blank">lees je hier!</a>',
    },
    copyrightThisYear: {
      label: 'Auteursrechten {{year}}',
      tooltip:
        'Totaalbedrag auteursvergoeding van alle betaalde facturen dit jaar. <br>Alles over de jaarlijkse limiet van je auteursvergoeding kan je <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank">hier lezen</a>.',
    },
    overdueInvoices: 'Vervallen facturen',
    pendingInvoices: 'Facturen ter goedkeuring',
    pendingQuotations: 'Offertes ter goedkeuring',
  },
};
