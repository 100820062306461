import { z } from 'zod';

import { europeanUnionVatCountryCodes } from '~/constants/countries';
import { Language } from '~/types/app';
import { includes } from '~/utils/arrays';

import type { EuVatCountryCode } from '../common/types';

import { countryCodeSchema, iso8601DateTimeSchema } from '../common/schemas';

// Vat number

export const belgianVatNumberSchema = z.string().refine((value): value is `BE${string}` => value.slice(0, 2) === 'BE');
export const europeanVatNumberSchema = z
  .string()
  .refine((value): value is `${EuVatCountryCode}${string}` => includes(europeanUnionVatCountryCodes, value.slice(0, 2)));

// Enterprise type
// prettier-ignore
export const enterpriseTypeSchema = z.union([
  z.literal(0),   z.literal(1),   z.literal(2),   z.literal(3),   z.literal(6),   z.literal(7),   z.literal(8),   z.literal(9),   z.literal(10),  z.literal(11),
  z.literal(12),  z.literal(13),  z.literal(14),  z.literal(15),  z.literal(16),  z.literal(17),  z.literal(18),  z.literal(19),  z.literal(20),  z.literal(21),
  z.literal(22),  z.literal(23),  z.literal(25),  z.literal(26),  z.literal(27),  z.literal(28),  z.literal(29),  z.literal(30),  z.literal(40),  z.literal(51),
  z.literal(60),  z.literal(65),  z.literal(70),  z.literal(106), z.literal(107), z.literal(108), z.literal(109), z.literal(110), z.literal(114), z.literal(116),
  z.literal(117), z.literal(121), z.literal(123), z.literal(124), z.literal(125), z.literal(127), z.literal(128), z.literal(129), z.literal(151), z.literal(160),
  z.literal(200), z.literal(206), z.literal(208), z.literal(211), z.literal(212), z.literal(213), z.literal(214), z.literal(215), z.literal(217), z.literal(218),
  z.literal(225), z.literal(230), z.literal(235), z.literal(260), z.literal(265), z.literal(301), z.literal(302), z.literal(303), z.literal(310), z.literal(320),
  z.literal(325), z.literal(330), z.literal(340), z.literal(350), z.literal(370), z.literal(371), z.literal(372), z.literal(373), z.literal(374), z.literal(375),
  z.literal(376), z.literal(377), z.literal(378), z.literal(379), z.literal(380), z.literal(381), z.literal(382), z.literal(383), z.literal(384), z.literal(385),
  z.literal(386), z.literal(387), z.literal(388), z.literal(389), z.literal(390), z.literal(391), z.literal(392), z.literal(393), z.literal(400), z.literal(401),
  z.literal(411), z.literal(412), z.literal(413), z.literal(414), z.literal(415), z.literal(416), z.literal(417), z.literal(418), z.literal(419), z.literal(420),
  z.literal(421), z.literal(422), z.literal(451), z.literal(452), z.literal(453), z.literal(454), z.literal(506), z.literal(508), z.literal(510), z.literal(511),
  z.literal(512), z.literal(513), z.literal(514), z.literal(515), z.literal(560), z.literal(606), z.literal(608), z.literal(610), z.literal(612), z.literal(614),
  z.literal(616), z.literal(617), z.literal(651), z.literal(701), z.literal(702), z.literal(703), z.literal(704), z.literal(706), z.literal(716), z.literal(721),
  z.literal(722), z.literal(723), z.literal(724), z.literal(790), z.literal(999),
]);

// Address

export const addressTypeSchema = z.enum(['headquarters', 'establishment unit', 'contact']);
export const organisationAddressTypeSchema = addressTypeSchema.exclude(['contact']);

export const addressSchema = z.object({
  type: addressTypeSchema,
  street: z.string(),
  number: z.string(),
  zipCode: z.string(),
  city: z.string(),
  countryCode: countryCodeSchema,
});
export const organisationAddressSchema = addressSchema.extend({
  type: organisationAddressTypeSchema,
});

// Email config

export const contactEmailConfigSchema = z.object({
  type: z.enum(['user', 'client']),
  email: z.string(),
  messages: z.array(z.enum(['quotations', 'invoices & credit notes', 'reminders'])),
});

// Custom agreement

export const customAgreementSchema = z.object({
  id: z.number(),
  date: iso8601DateTimeSchema,
  description: z.string(),
});

// Contact

export const contactSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  fullName: z.string(),
  function: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  language: z.nativeEnum(Language),
  address: addressSchema,
  emails: z.array(contactEmailConfigSchema),
  comments: z.string().nullable(),
  customAgreements: z.array(customAgreementSchema),
  hasCustomAgreement: z.boolean(),
  deleted: z.boolean(),
});

// Client

const baseOrganisationClientSchema = z.object({
  id: z.number(),
  clientType: z.literal('organisation'),
  isInformalAssociation: z.literal(false),
  name: z.string(),
  enterpriseType: enterpriseTypeSchema,
  exceptionalZeroVat: z.boolean(),
  exceptionalZeroVatReason: z.string().nullable(),
  addresses: z.array(organisationAddressSchema),
  contacts: z.array(contactSchema),
  deleted: z.boolean(),
});

export const organisationWithVatNumberClientSchema = baseOrganisationClientSchema.extend({
  vatNumber: europeanVatNumberSchema,
  otherNumber: z.string().nullable(),
  vatValid: z.boolean(),
  vatActive: z.boolean(),
});

export const organisationWithOtherNumberClientSchema = baseOrganisationClientSchema.extend({
  vatNumber: z.null(),
  otherNumber: z.string(),
  vatValid: z.literal(false),
  vatActive: z.literal(false),
});

export const organisationClientSchema = z.union([organisationWithVatNumberClientSchema, organisationWithOtherNumberClientSchema]);

export const privateClientSchema = z.object({
  id: z.number(),
  clientType: z.literal('private'),
  isInformalAssociation: z.boolean(),
  name: z.string(),
  enterpriseType: z.literal(0),
  vatNumber: z.null(),
  otherNumber: z.null(),
  vatValid: z.literal(false),
  vatActive: z.literal(false),
  exceptionalZeroVat: z.literal(false),
  exceptionalZeroVatReason: z.null(),
  addresses: z.tuple([]),
  contacts: z.array(contactSchema).length(1),
  deleted: z.boolean(),
});

export const clientSchema = z.union([organisationWithVatNumberClientSchema, organisationWithOtherNumberClientSchema, privateClientSchema]);

export const clientForClientQuotationSchema = z.object({
  name: z.string(),
  vatNumber: z.string().nullable(),
  otherNumber: z.string().nullable(),
  language: z.nativeEnum(Language),
  address: z.object({
    street: z.string(),
    number: z.string(),
    zipCode: z.string(),
    city: z.string(),
  }),
});

// VAT information

export const kboVatInformationSchema = z.object({
  source: z.literal('KBO'),
  vatNumber: belgianVatNumberSchema,
  vatValid: z.literal(true),
  vatActive: z.boolean(),
  companyName: z.string(),
  enterpriseType: enterpriseTypeSchema,
  establishmentUnitsCount: z.number(),
  establishmentUnits: z.array(organisationAddressSchema),
  hasEligibleNacebelCodes: z.boolean(),
  founder: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
    })
    .nullable(),
});

export const viesVatInformationSchema = z.object({
  source: z.literal('VIES'),
  vatNumber: europeanVatNumberSchema,
  vatValid: z.literal(true),
  vatActive: z.literal(true),
  companyName: z.string().nullable(),
  enterpriseType: z.literal(0),
  establishmentUnitsCount: z.literal(0),
  establishmentUnits: z.tuple([]),
  hasEligibleNacebelCodes: z.literal(false),
  founder: z.null(),
});

export const invalidVatInformationSchema = z.object({
  source: z.enum(['KBO', 'VIES']),
  vatNumber: z.string(),
  vatValid: z.literal(false),
  vatActive: z.literal(false),
  companyName: z.null(),
  enterpriseType: z.literal(0),
  establishmentUnitsCount: z.literal(0),
  establishmentUnits: z.tuple([]),
  hasEligibleNacebelCodes: z.literal(false),
  founder: z.null(),
});

export const vatInformationSchema = z.union([kboVatInformationSchema, viesVatInformationSchema, invalidVatInformationSchema]);
export const belgianVatInformationSchema = z.union([kboVatInformationSchema, invalidVatInformationSchema]);
